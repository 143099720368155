// 20211115RBP - Added custom dynamic form extensions for our Date of Birth property
// based on: https://docs.abp.io/en/abp/latest/UI/Angular/Dynamic-Form-Extensions#step-2-import-and-use-form-prop-contributors
// src/app/form-prop-contributors.ts

import {
  eIdentityComponents,
  IdentityCreateFormPropContributors,
  IdentityUserDto,
} from '@volo/abp.ng.identity';
import { ePropType, FormProp, FormPropList } from '@abp/ng.theme.shared/extensions';
import { Validators } from '@angular/forms';
import { ConfigStateService } from '@abp/ng.core';

const dateOfBirthProp = new FormProp<IdentityUserDto>({
  type: ePropType.Date,
  name: 'DateOfBirth',
  displayName: 'Simplinity::DateOfBirth',
  isExtra: true,
  validators: (x) => {
    const svc = x.getInjected(ConfigStateService);
    const value = svc.getFeature('Account.DateOfBirthRequired');

    return (value === 'true') ? [Validators.required] : [];
  },
});

export function dateOfBirthPropContributor(propList: FormPropList<IdentityUserDto>) {

  // Replace the default date of birth property because it doesn't render correctly
  const prop = propList.find(x => x.value.name === 'DateOfBirth');
  const previousValue = prop.value;
  const newProp = new FormProp<IdentityUserDto>(
    Object.assign({},
    previousValue,
    {
      type: ePropType.Date,
      validators: (x: { getInjected: (arg0: typeof ConfigStateService) => any; }) => {

        // Add required validator if feature is enabled
        const svc = x.getInjected(ConfigStateService);
        const value = svc.getFeature('Account.DateOfBirthRequired');

        return (value === 'true') ? [Validators.required] : []
      }
    }));

  propList.addAfter(newProp, previousValue);
  propList.dropByValue(previousValue);
}

export const identityCreateFormPropContributors: IdentityCreateFormPropContributors = {
  // enum indicates the page to add contributors to
  [eIdentityComponents.Users]: [
    dateOfBirthPropContributor,
    // You can add more contributors here
  ],
};

export const identityEditFormPropContributors = identityCreateFormPropContributors;
// you may define different contributors for edit form if you like


